/* 전역적 & 특정 스타일 css를 관리 */

@font-face {
  font-family: "Pretendard-Regular";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 400;
  font-style: normal;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
button,
input,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: "Pretendard-Regular";
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  cursor: pointer;
  font-weight: 700;
  color: black;
  background-color: #fff;
}

input::placeholder {
  font-family: "Pretendard-Regular";
}

textarea::placeholder {
  font-family: "Pretendard-Regular";
  font-weight: 400;
}

a {
  color: black;
  text-decoration: none;
}

:root {
  --main-color: #fec91f;
  --sub-color: #ff9852;
  --gray-200: #f2f2f2;
  --gray-300: #d7d7d7;
  --gray-400: #bebebe;
  --gray-700: #606060;
  --gray-900: #333333;
  --box-shadow: 0 0 2px rgba(0, 0, 0, 0.08), 4px 12px 36px rgba(0, 0, 0, 0.08);
}

::-webkit-scrollbar {
  background-color: var(--gray-200);
  border-radius: 10px;
  width: 10px;
}

/* 스크롤바 막대 꾸미기 */
::-webkit-scrollbar-thumb {
  background-color: var(--gray-400);
  border-radius: 10px;
}

/* 스크롤바 트랙 꾸미기 */
::-webkit-scrollbar-track {
  background-color: var(--gray-200);
  border-radius: 10px;
}
